import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets  = [ "query", "empty", "vehicles", "loading"]
    static values = { url: String }

    connect(){
        console.log("basic cost connected")
    }

    search(){

        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {

            let url = new URL(this.urlValue);
            url.searchParams.append('query', this.queryTarget.value);    
            url.searchParams.append('e', this.emptyTarget.checked);    
            this.vehiclesTarget.src = url.toString();

            var queryParams = new URLSearchParams(window.location.search);
            queryParams.set("query", this.queryTarget.value);
            queryParams.set("e", this.emptyTarget.checked);
            history.replaceState(null, null, "?"+queryParams.toString());

        }, 200)
    }

    displayLoading(event){
        //this.loadingTarget.classList.remove('d-none');
        //this.vehiclesTarget.classList.add('d-none');
    }

    displayContent() {
        //this.loadingTarget.classList.add('d-none');
        //this.vehiclesTarget.classList.remove('d-none');
    }
}