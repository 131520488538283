import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = [ "baseMileage" , "rows"]

    connect() {
        this.element[this.identifier] = this        
        this.update()
    }
 
    baseMileageUpdate(){
        var base_mileage = parseFloat(this.baseMileageTarget.value) || 0
        
        this.baseMileageTarget.value = base_mileage.swedish_round()        
    }

    update(){
        this.baseMileageUpdate()
        this.updateSummary()
    }

    updateSummary() {
        this.rowsTargets.forEach(element => {
            var fixed_cost = parseFloat(element.getElementsByClassName("vehicle-fixed-cost")[0].innerHTML) || 0
            var variable_cost = parseFloat(element.getElementsByClassName("vehicle-variable-cost")[0].innerHTML) || 0
            var base_mileage = parseFloat(this.baseMileageTarget.value) || 0

            var summary = fixed_cost + ( base_mileage * variable_cost )

            element.getElementsByClassName("summary")[0].innerHTML = summary.swedish_round()
        });
    }

    saveInvestment(e) {
        this.element.submit();
    }

    saveCompareJob() {
    }
}
