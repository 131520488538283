import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("submit:connect ")
  }

  submit(){    
    //Rails.fire(form, 'submit'); use this method after moving to Rails-RJS
    //until this use follow hack
    this.submitForm(this.element)
  }

  submitForm(form) {
    var button = form.ownerDocument.createElement('input');
    button.style.display = 'none';
    button.type = 'submit';
    form.appendChild(button).click();
    form.removeChild(button);
  }
}
