import { Controller } from "@hotwired/stimulus";


export default class extends Controller {

    static targets = ["button"]

    connect() {
        console.log("form-delete-link")    
        if(this.hasButtonTarget)       
            this.buttonTarget.style.display = 'none'
    }

    submit_delete_link(event) {
        event.preventDefault()
        console.log("let's delete")

        this.buttonTarget.click()
    }
}