import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    console.log("connected to draf controller");

    this.sortable = Sortable.create(this.element, {
        onEnd: this.end.bind(this),
        handle: 'i.handle',
        animation: 150
    })
  }

  end(event) {
    let newIndex = event.newIndex + 1
    let id = event.item.dataset.id
    let type = event.item.dataset.type
    let url = this.data.get("url").replace(":id", id)

    let data = null
    if (type == "chapter") {
      data = { chapter: { position: newIndex} }  
    }
    else if (type == "lesson") {
      data = { lesson: { position: newIndex} }
    }
    
    if (data)
      $.post( url, data);
  }
}
