import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String, isRead: Boolean }

  connect() {
    console.log(this.isReadValue)

    if(!this.isReadValue){
      this.read()
    }
  }

  read(){
    $.ajax({type: 'PATCH', url: this.urlValue});
  }
}