import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        console.log("learn");
        this.learn();
    }

    learn(){
        let url = this.data.get("url");
        $.post( url);
    }
}
  