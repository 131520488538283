import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets  = [ "vehicleListId", "empty", "vehicles", "loading", "month", "exportForm"]
    static values = { url: String, exporturl: String}

    connect(){
        $(".custom-selectpicker").selectpicker()
        this.setupDate()
    }

    search(){
        clearTimeout(this.timeout)
        this.showLoading(true)
        this.timeout = setTimeout(() => {            
            let url = new URL(this.urlValue)
            
            if (this.hasVehicleListIdTarget) {
                url.searchParams.append('vehicle_list_id', this.vehicleListIdTarget.value)
            }

            url.searchParams.append('month', this.monthTarget.value)
            this.vehiclesTarget.src = url.toString()

            if (this.hasExportFormTarget){
                let exportUrl = new URL(this.exporturlValue)

                if (this.hasVehicleListIdTarget) {
                    exportUrl.searchParams.append('vehicle_list_id', this.vehicleListIdTarget.value)
                }
    
                exportUrl.searchParams.append('month', this.monthTarget.value)

                this.exportFormTarget.parentElement.setAttribute("action", exportUrl)
            }

        }, 200)
    }

    displayLoading(event){
        //this.loadingTarget.classList.remove('d-none');
        //this.vehiclesTarget.classList.add('d-none');
        
    }

    displayContent() {
        //this.loadingTarget.classList.add('d-none');
        //this.vehiclesTarget.classList.remove('d-none');
        this.showLoading(false)
    }

    showLoading(value){
        if(value){
            this.loadingTarget.classList.remove('d-none');
            this.vehiclesTarget.classList.add('d-none');
        }else{
            this.loadingTarget.classList.add('d-none');
            this.vehiclesTarget.classList.remove('d-none');
        }
    }

    setupDate(){
        $(this.monthTarget).datepicker(
            {
              format: "yyyy-mm-dd",
              autoclose: false,
              language: "sv",
              minViewMode: 1,
              maxViewMode: 2,    
              autoclose: true                
            })
            .on('changeDate', function(e) {
              const event = new Event('change')
              this.dispatchEvent(event)
            })      
    }

    monthChanged(){
        this.search()
    }
}