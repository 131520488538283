import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "connectStatus" ]  

  connect(){
    console.log("connected")
  }

  connectStatusTargetConnected(element) {    
    if(this.connectStatusTarget.getAttribute("data-connect-value") == "true"){      
      $("#job_page_p_administration_cost_per_hour_1").prop("readonly", true)
    }else{
      $("#job_page_p_administration_cost_per_hour_1").prop("readonly", false)
    }
        
  }
}