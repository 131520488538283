// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "email", "emailNotice" ]

  initialize() {
    console.log('stimulus new child user!');
  }

  connect() {}

  disconnet() {}

  emailChanged() {
    $.post( "/manage_child_users/email_status", { user: { email: this.emailTarget.value} });
  }

  
}