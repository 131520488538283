import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets  = [ "query", "users"]
    static values = { url: String }

    connect(){
        console.log("search connected")

        console.log(this.urlValue)
    }

    search(){
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            let url = new URL(this.urlValue);
            url.searchParams.append('query', this.queryTarget.value);    
            this.usersTarget.src = url.toString();
        }, 200)
    }
}