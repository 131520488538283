import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("connected to remove item controller");    
  }

  remove() {
    this.element.parentElement.remove();
  }
}