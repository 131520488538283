import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {

  static values = {
    closeOnSubmit: Boolean
  }
  connect() {
    console.log("connected to modal controller" + this.closeOnSubmitValue)
    $("#modal").modal('show')

    if( this.closeOnSubmitValue ) {
      document.addEventListener('turbo:submit-end', this.handleSubmit)
    }
      
  }
  disconnect() {
    if( this.closeOnSubmitValue ) {
      document.removeEventListener('turbo:submit-end', this.handleSubmit)
    }
  }

  close() {
    console.log("close");
    
    $("#modal").modal('hide')
    $("#modal-frame").removeAttr("src")
    $("#video-modal-frame").removeAttr("src")
    this.element.remove()
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.close()
    }    
  }

  handleSubmit = (e) => {
    console.log("handleSubmit");
    if (e.detail.success) {      
      this.close()
    }
  }
}
