import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets  = [ "month" ]

  connect() {
    console.log("connected working day")    

    $(this.monthTarget).datepicker(
      {
        format: "yyyy-mm-dd",
        autoclose: false,
        language: "sv",
        minViewMode: 1,
        maxViewMode: 2,    
        autoclose: true                
      })
      .on('changeDate', function(e) {
        const event = new Event('change')
        this.dispatchEvent(event)
      })      
  }

  monthChanged(){
    this.element.closest("form").requestSubmit();
  }
}