// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "plan"]

  initialize() {
    console.log('stimulus registration compelte!');
  }

  connect() {
    this.planChanged();
  }

  disconnet() {
    console.log('stimulus disconnect! registration complete');
  }

  planChanged() {    
    $.get( "/home/plan_info", { plan_id: this.planTarget.value } );
  }
}
