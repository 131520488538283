// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js or *_controller.ts.

import { Application } from "@hotwired/stimulus"
import Remote from "stimulus-remote-rails"
//Show more using strimulus
import ReadMore from 'stimulus-read-more'

import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
application.register("remote", Remote)
application.register('read-more', ReadMore)

window.Stimulus = application


const context = require.context("controllers", true, /_controller\.(js|ts)$/)
Stimulus.load(definitionsFromContext(context))