import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["truckFixedCostValue", "truckFixedCostPrice", "truckFixedCostSummary", "truckFixedCostSectionSummary", 
                    "truckMileageCostValue", "truckMileageCostPrice", "truckMileageCostSummary",
                    "truckNotChargeableMileageValue", "truckNotChargeableMileagePrice", "truckNotChargeableMileageSummary",
                    "truckCoolingValue", "truckCoolingPrice", "truckCoolingSummary", "truckMileageCostSectionSummary",
                    "personalNormalHoursValue", "personalNormalHoursPrice", "personalNormalHoursSummary",
                    "personalOvertimeValue", "personalOvertimePrice", "personalOvertimeSummary",
                    "personalOb1Value", "personalOb1Price", "personalOb1Summary",
                    "personalOb2Value", "personalOb2Price", "personalOb2Summary", "personalSectionSummary",
                    "otherCosts1Cost1", "otherCosts1Cost2", "otherCosts1Cost3", "otherCosts1Summary",
                    "otherCosts2Cost1", "otherCosts2Cost2", "otherCosts2Cost3", "otherCosts2Summary",
                    "otherCosts3Cost1", "otherCosts3Cost2", "otherCosts3Cost3", "otherCosts3Summary",
                    "directCosts1Summary", "directCosts2Summary", "directCosts3Summary", "directCosts4Summary",
                    "indirectVariableCost", "indirectVariableCostSummary",
                    "indirectNormalHourCost", "indirectNormalHourCostSummary",
                    "indirectShareOfDirectCost", "indirectShareOfDirectCost1Summary", "indirectShareOfDirectCost2Summary", "indirectShareOfDirectCost3Summary",
                    "profitSurchargePercentage", "profitSurcharge1Summary", "profitSurcharge2Summary", "profitSurcharge3Summary",
                    "commissionPercentage", "commission1Summary", "commission2Summary", "commission3Summary",
                    "surcharge1Summary", "surcharge2Summary", "surcharge3Summary", "surcharge4Summary",
                    "manualCost1", "manualCost2", "manualCost3",
                    "chargeableCost1", "chargeableCost2", "chargeableCost3", "chargeableCost4",
                    "finalSummary1", "finalSummary2", "finalSummary3", "finalSummary4",
                    "combinedRatePerHour", "combinedRatePerDistanceUnit"]

  connect() {    
    this.update()
  }

  update(){
    this.updateTruckFixedCost()
    this.updateTruckMileageCost()
    this.updatePersonalCost()
    this.updateOtherCosts()
    this.updateDirectCosts()
    this.updateIndirectCosts()
    this.updateSummary()
  }

  getTruckFixedCost() {
    var truckFixedCostValue = parseFloat(this.truckFixedCostValueTarget.value) || 0
    var truckFixedCostPrice = parseFloat(this.truckFixedCostPriceTarget.innerHTML) || 0
    return truckFixedCostValue * truckFixedCostPrice
  }

  getTruckMileageCost(){
    var truckMileageCostValue = parseFloat(this.truckMileageCostValueTarget.value) || 0
    var truckMileageCostPrice = parseFloat(this.truckMileageCostPriceTarget.innerHTML) || 0
    return truckMileageCostValue * truckMileageCostPrice
  }

  getTruckNotChargeableMileageCost(){
    var truckNotChargeableMileageValue = parseFloat(this.truckNotChargeableMileageValueTarget.value) || 0
    var truckNotChargeableMileagePrice = parseFloat(this.truckNotChargeableMileagePriceTarget.innerHTML) || 0
    return truckNotChargeableMileageValue * truckNotChargeableMileagePrice
  }
  
  getTruckCoolingCost(){
    var truckCoolingValue = parseFloat(this.truckCoolingValueTarget.value) || 0
    var truckCoolingPrice = parseFloat(this.truckCoolingPriceTarget.value) || 0
    return truckCoolingValue * truckCoolingPrice
  }

  updateTruckMileageSummary(){
    return this.getTruckMileageCost() + this.getTruckNotChargeableMileageCost() + this.getTruckCoolingCost()
  }

  getPersonalNormalHoursCost(){
    var personalNormalHoursValue = parseFloat(this.personalNormalHoursValueTarget.value) || 0
    var personalNormalHoursPrice = parseFloat(this.personalNormalHoursPriceTarget.innerHTML) || 0
    return personalNormalHoursValue * personalNormalHoursPrice
  }

  getPersonalOvertimeCost(){
    var personalOvertimeValue = parseFloat(this.personalOvertimeValueTarget.value) || 0
    var personalOvertimePrice = parseFloat(this.personalOvertimePriceTarget.innerHTML) || 0
    return personalOvertimeValue * personalOvertimePrice
  }

  getPersonalOb1Cost(){
    var personalOb1Value = parseFloat(this.personalOb1ValueTarget.value) || 0
    var personalOb1Price = parseFloat(this.personalOb1PriceTarget.innerHTML) || 0
    return personalOb1Value * personalOb1Price
  }

  getPersonalOb2Cost(){
    var personalOb2Value = parseFloat(this.personalOb2ValueTarget.value) || 0
    var personalOb2Price = parseFloat(this.personalOb2PriceTarget.innerHTML) || 0
    return personalOb2Value * personalOb2Price
  }

  updatePersonalCostSummary(){
    return this.getPersonalNormalHoursCost() + this.getPersonalOvertimeCost() + this.getPersonalOb1Cost() + this.getPersonalOb2Cost()
  }


  getOtherCosts1Summary() {
    var otherCosts1Cost1 = parseFloat(this.otherCosts1Cost1Target.value) || 0
    var otherCosts1Cost2 = parseFloat(this.otherCosts1Cost2Target.value) || 0
    var otherCosts1Cost3 = parseFloat(this.otherCosts1Cost3Target.value) || 0

    return otherCosts1Cost1 + otherCosts1Cost2 + otherCosts1Cost3
  }

  getOtherCosts2Summary() {
    var otherCosts2Cost1 = parseFloat(this.otherCosts2Cost1Target.value) || 0
    var otherCosts2Cost2 = parseFloat(this.otherCosts2Cost2Target.value) || 0
    var otherCosts2Cost3 = parseFloat(this.otherCosts2Cost3Target.value) || 0

    return otherCosts2Cost1 + otherCosts2Cost2 + otherCosts2Cost3
  }

  getOtherCosts3Summary() {
    var otherCosts3Cost1 = parseFloat(this.otherCosts3Cost1Target.value) || 0
    var otherCosts3Cost2 = parseFloat(this.otherCosts3Cost2Target.value) || 0
    var otherCosts3Cost3 = parseFloat(this.otherCosts3Cost3Target.value) || 0

    return otherCosts3Cost1 + otherCosts3Cost2 + otherCosts3Cost3
  }

  getDirectCosts1Summary(){
    var otherCosts1Cost1 = parseFloat(this.otherCosts1Cost1Target.value) || 0
    var otherCosts2Cost1 = parseFloat(this.otherCosts2Cost1Target.value) || 0
    var otherCosts3Cost1 = parseFloat(this.otherCosts3Cost1Target.value) || 0

    return this.getTruckFixedCost() + otherCosts1Cost1 + otherCosts2Cost1 + otherCosts3Cost1
  }

  getDirectCosts2Summary(){    
    var otherCosts1Cost2 = parseFloat(this.otherCosts1Cost2Target.value) || 0
    var otherCosts2Cost2 = parseFloat(this.otherCosts2Cost2Target.value) || 0
    var otherCosts3Cost2 = parseFloat(this.otherCosts3Cost2Target.value) || 0

    return this.getTruckMileageCost() + this.getTruckNotChargeableMileageCost() + this.getTruckCoolingCost() + otherCosts1Cost2 + otherCosts2Cost2 + otherCosts3Cost2
  }

  getDirectCosts3Summary(){

    var otherCosts1Cost3 = parseFloat(this.otherCosts1Cost3Target.value) || 0
    var otherCosts2Cost3 = parseFloat(this.otherCosts2Cost3Target.value) || 0
    var otherCosts3Cost3 = parseFloat(this.otherCosts3Cost3Target.value) || 0

    return this.getPersonalNormalHoursCost() + this.getPersonalOvertimeCost() + this.getPersonalOb1Cost() + this.getPersonalOb2Cost() + otherCosts1Cost3 + otherCosts2Cost3 + otherCosts3Cost3
  }

  getDirectCosts4Summary(){    
    return this.getTruckFixedCost() + this.updateTruckMileageSummary() + this.updatePersonalCostSummary() + this.getOtherCosts1Summary() + this.getOtherCosts2Summary() + this.getOtherCosts3Summary()
  }

  getIndirectVariableCostSummary(){
    var truckMileageCostValue = parseFloat(this.truckMileageCostValueTarget.value) || 0
    var indirectVariableCost = parseFloat(this.indirectVariableCostTarget.value) || 0
    return truckMileageCostValue * indirectVariableCost
  }

  getIndirectNormalHourCostSummary(){
    var personalNormalHoursValue = parseFloat(this.personalNormalHoursValueTarget.value) || 0
    var indirectNormalHourCost = parseFloat(this.indirectNormalHourCostTarget.value) || 0
    return personalNormalHoursValue * indirectNormalHourCost
  }

  getIndirectShareOfDirectCost1Summary(){
    var indirectShareOfDirectCost = parseFloat(this.indirectShareOfDirectCostTarget.value) || 0
    var directCosts1Summary = this.getDirectCosts1Summary()

    var summary = directCosts1Summary * indirectShareOfDirectCost / 100.0
    return summary
  }

  getIndirectShareOfDirectCost2Summary(){
    var indirectShareOfDirectCost = parseFloat(this.indirectShareOfDirectCostTarget.value) || 0
    var directCosts2Summary = this.getDirectCosts2Summary()

    var summary = directCosts2Summary * indirectShareOfDirectCost / 100.0
    return summary
  }

  getIndirectShareOfDirectCost3Summary(){
    var indirectShareOfDirectCost = parseFloat(this.indirectShareOfDirectCostTarget.value) || 0
    var directCosts3Summary = this.getDirectCosts3Summary()

    var summary = directCosts3Summary * indirectShareOfDirectCost / 100.0
    return summary
  }

  getProfitSurcharge1Summary(){
    var profitSurchargePercentage = parseFloat(this.profitSurchargePercentageTarget.value) || 0
    var directCosts1Summary = this.getDirectCosts1Summary()

    var summary = directCosts1Summary * profitSurchargePercentage / 100.0

    return summary
  }

  getProfitSurcharge2Summary(){
    var profitSurchargePercentage = parseFloat(this.profitSurchargePercentageTarget.value) || 0
    var directCosts2Summary = this.getDirectCosts2Summary()

    var summary = directCosts2Summary * profitSurchargePercentage / 100.0

    return summary
  }

  getProfitSurcharge3Summary(){
    var profitSurchargePercentage = parseFloat(this.profitSurchargePercentageTarget.value) || 0
    var directCosts3Summary = this.getDirectCosts3Summary()

    var summary = summary = directCosts3Summary * profitSurchargePercentage / 100.0

    return summary
  }

  getCommission1Summary(){
    var commissionPercentage = parseFloat(this.commissionPercentageTarget.value) || 0
    var part1 = this.getDirectCosts1Summary() + this.getIndirectShareOfDirectCost1Summary() + this.getProfitSurcharge1Summary()
    var part2 = ( commissionPercentage / 100.0 ) / (1 - ( commissionPercentage / 100.0 ) )

    return part1 * part2
  }

  getCommission2Summary(){
    var commissionPercentage = parseFloat(this.commissionPercentageTarget.value) || 0
    var part1 = this.getDirectCosts2Summary() + this.getIndirectVariableCostSummary() + this.getIndirectShareOfDirectCost2Summary()  + this.getProfitSurcharge2Summary()
    var part2 = ( commissionPercentage / 100.0 ) / (1 - ( commissionPercentage / 100.0 ) )

    return part1 * part2
  }

  getCommission3Summary(){
    var commissionPercentage = parseFloat(this.commissionPercentageTarget.value) || 0
    var part1 = this.getDirectCosts3Summary() + this.getIndirectNormalHourCostSummary() + this.getIndirectShareOfDirectCost3Summary()  + this.getProfitSurcharge3Summary()
    var part2 = ( commissionPercentage / 100.0 ) / (1 - ( commissionPercentage / 100.0 ) )

    return part1 * part2
  }

  getSurcharge1Summary(){
    var manualCost1 = parseFloat(this.manualCost1Target.value) || 0
    return this.getDirectCosts1Summary() + this.getIndirectShareOfDirectCost1Summary() + this.getProfitSurcharge1Summary() + this.getCommission1Summary() + manualCost1
  }

  getSurcharge2Summary(){
    var manualCost2 = parseFloat(this.manualCost2Target.value) || 0
    return this.getDirectCosts2Summary() + this.getIndirectVariableCostSummary() + this.getIndirectShareOfDirectCost2Summary()  + this.getProfitSurcharge2Summary() + this.getCommission2Summary() + manualCost2
  }

  getSurcharge3Summary(){
    var manualCost3 = parseFloat(this.manualCost3Target.value) || 0
    return this.getDirectCosts3Summary() + this.getIndirectNormalHourCostSummary() + this.getIndirectShareOfDirectCost3Summary()  + this.getProfitSurcharge3Summary() + this.getCommission3Summary() + manualCost3
  }

  getSurcharge4Summary(){
    return this.getSurcharge1Summary() + this.getSurcharge2Summary() + this.getSurcharge3Summary()
  }

  getFinal1Summary(){
    var chargeableCost1 = parseFloat(this.chargeableCost1Target.value) || 0
    var surcharge1Summary = this.getSurcharge1Summary()
    var total = 0

    if (chargeableCost1 != 0) {
      total = surcharge1Summary / chargeableCost1
    }

    return total
  }

  getFinal2Summary(){
    var chargeableCost2 = parseFloat(this.chargeableCost2Target.value) || 0
    var surcharge2Summary = this.getSurcharge2Summary()
    var total = 0

    if (chargeableCost2 != 0) {
      total = surcharge2Summary / chargeableCost2
    }

    return total
  }

  getFinal3Summary(){
    var chargeableCost3 = parseFloat(this.chargeableCost3Target.value) || 0
    var surcharge3Summary = this.getSurcharge3Summary()
    var total = 0

    if (chargeableCost3 != 0) {
      total = surcharge3Summary / chargeableCost3
    }

    return total
  }

  getFinal4Summary(){
    var chargeableCost4 = parseFloat(this.chargeableCost4Target.value) || 0
    var surcharge4Summary = this.getSurcharge4Summary()
    var total = 0

    if (chargeableCost4 != 0) {
      total = surcharge4Summary / chargeableCost4
    }

    return total
  }

  getCombinedRatePerHour(){
    return this.getFinal1Summary() + this.getFinal3Summary()
  }

  updateTruckFixedCost(){
    var truckFixedCostSummary = this.getTruckFixedCost();

    this.truckFixedCostSummaryTarget.innerHTML = truckFixedCostSummary.swedish_format()
    this.truckFixedCostSectionSummaryTarget.innerHTML = truckFixedCostSummary.swedish_format()
  }

  updateTruckMileageCost(){
    this.truckMileageCostSummaryTarget.innerHTML = this.getTruckMileageCost().swedish_format()
    this.truckNotChargeableMileageSummaryTarget.innerHTML = this.getTruckNotChargeableMileageCost().swedish_format()    
    this.truckCoolingSummaryTarget.innerHTML = this.getTruckCoolingCost().swedish_format() 

    this.truckMileageCostSectionSummaryTarget.innerHTML = this.updateTruckMileageSummary().swedish_format() 
  }

  updatePersonalCost(){
    this.personalNormalHoursSummaryTarget.innerHTML = this.getPersonalNormalHoursCost().swedish_format()
    this.personalOvertimeSummaryTarget.innerHTML = this.getPersonalOvertimeCost().swedish_format()
    this.personalOb1SummaryTarget.innerHTML = this.getPersonalOb1Cost().swedish_format()
    this.personalOb2SummaryTarget.innerHTML = this.getPersonalOb2Cost().swedish_format()

    this.personalSectionSummaryTarget.innerHTML = this.updatePersonalCostSummary().swedish_format() 
  }

  updateOtherCosts() {
    this.otherCosts1SummaryTarget.innerHTML = this.getOtherCosts1Summary().swedish_format()
    this.otherCosts2SummaryTarget.innerHTML = this.getOtherCosts2Summary().swedish_format()
    this.otherCosts3SummaryTarget.innerHTML = this.getOtherCosts3Summary().swedish_format()    
  }

  updateDirectCosts(){
    this.directCosts1SummaryTarget.innerHTML = this.getDirectCosts1Summary().swedish_format() 
    this.directCosts2SummaryTarget.innerHTML = this.getDirectCosts2Summary().swedish_format()  
    this.directCosts3SummaryTarget.innerHTML = this.getDirectCosts3Summary().swedish_format()  
    this.directCosts4SummaryTarget.innerHTML = this.getDirectCosts4Summary().swedish_format() 
  }

  updateIndirectCosts(){
    this.indirectVariableCostSummaryTarget.innerHTML = this.getIndirectVariableCostSummary().swedish_format() 
    this.indirectNormalHourCostSummaryTarget.innerHTML = this.getIndirectNormalHourCostSummary().swedish_format() 

    this.indirectShareOfDirectCost1SummaryTarget.innerHTML = this.getIndirectShareOfDirectCost1Summary().swedish_format() 
    this.indirectShareOfDirectCost2SummaryTarget.innerHTML = this.getIndirectShareOfDirectCost2Summary().swedish_format() 
    this.indirectShareOfDirectCost3SummaryTarget.innerHTML = this.getIndirectShareOfDirectCost3Summary().swedish_format() 
    
    this.profitSurcharge1SummaryTarget.innerHTML = this.getProfitSurcharge1Summary().swedish_format() 
    this.profitSurcharge2SummaryTarget.innerHTML = this.getProfitSurcharge2Summary().swedish_format() 
    this.profitSurcharge3SummaryTarget.innerHTML = this.getProfitSurcharge3Summary().swedish_format()

    this.commission1SummaryTarget.innerHTML = this.getCommission1Summary().swedish_format() 
    this.commission2SummaryTarget.innerHTML = this.getCommission2Summary().swedish_format() 
    this.commission3SummaryTarget.innerHTML = this.getCommission3Summary().swedish_format()

    this.surcharge1SummaryTarget.innerHTML = this.getSurcharge1Summary().swedish_format()
    this.surcharge2SummaryTarget.innerHTML = this.getSurcharge2Summary().swedish_format()
    this.surcharge3SummaryTarget.innerHTML = this.getSurcharge3Summary().swedish_format()
    this.surcharge4SummaryTarget.innerHTML = this.getSurcharge4Summary().swedish_format()
  }

  updateSummary(){
    this.finalSummary1Target.innerHTML = this.getFinal1Summary().swedish_format()
    this.finalSummary2Target.innerHTML = this.getFinal2Summary().swedish_format()
    this.finalSummary3Target.innerHTML = this.getFinal3Summary().swedish_format()
    this.finalSummary4Target.innerHTML = this.getFinal4Summary().swedish_format()


    this.combinedRatePerHourTarget.innerHTML = this.getCombinedRatePerHour().swedish_format()
    this.combinedRatePerDistanceUnitTarget.innerHTML = this.getFinal2Summary().swedish_format()
  }
  
}