import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets  = [ "query", "jobs"]
    static values = { url: String }

    connect(){
        console.log("compare jobs connected")
    }

    search(){

        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {

            let url = new URL(this.urlValue);
            url.searchParams.append('query', this.queryTarget.value);
            this.jobsTarget.src = url.toString();

        }, 200)
    }

    displayLoading(event){
        //this.loadingTarget.classList.remove('d-none');
        //this.vehiclesTarget.classList.add('d-none');
    }

    displayContent() {
        //this.loadingTarget.classList.add('d-none');
        //this.vehiclesTarget.classList.remove('d-none');
    }
}