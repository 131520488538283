import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["loadWeight", "plusWeight", "timeOnRoadPercentage", "workingTimeInMinutes", "numberOfTurns", "speed", "distance", "loadingTimeInMinutes", "unloadingTimeInMinutes", "fuelingTimeInMinutes", "summaryTotalWeight", "summaryTotalDistance1", "summaryTotalDistance2", "summaryTotalDistancePerMinute", "summaryLoadingUnloadingTime", "summaryAdjustedTime", "summaryTotalDistance", "summaryTotalCost", "summaryTotalCostPerUnit", "summaryVehicleFixedCostPerDay", "summaryVehicleVariableCost", "originalFuelConsumption", "fuelConsumption", "fuelUpdatedAlert"]

    connect() {
        console.log("compare weights")
        this.update()
    }
 
    update(){

        this.summaryTotalDistance1Target.innerHTML = this.summaryTotalDistance().swedish_format()
        this.summaryTotalDistance2Target.innerHTML = this.summaryTotalDistance().swedish_format()
        this.summaryTotalDistancePerMinuteTarget.innerHTML = this.summaryTotalDistancePerMinute().swedish_format()

        this.summaryLoadingUnloadingTimeTarget.innerHTML = this.summaryLoadingUnloadingTime().swedish_format()
        this.summaryAdjustedTimeTarget.innerHTML = this.summaryAdjustedTime().swedish_format()

        this.summaryTotalCostTarget.innerHTML = this.summaryTotalCost().swedish_format()

        this.summaryTotalWeightTarget.innerHTML = this.summaryTotalWeight().swedish_format()
        this.summaryTotalCostPerUnitTarget.innerHTML = this.summaryTotalCostPerUnit().swedish_format()
    }

    fuelConsumptionUpdated(){
        var originalFuelConsumption = parseFloat(this.originalFuelConsumptionTarget.value) || 0
        var fuelConsumption = parseFloat(this.fuelConsumptionTarget.value) || 0
        if ( originalFuelConsumption != fuelConsumption ) {
            this.fuelUpdatedAlertTarget.classList.remove("d-none")
        }else {
            this.fuelUpdatedAlertTarget.classList.add("d-none")
        }
    }

    summaryTotalDistance(){
        var number_of_turns = parseFloat(this.numberOfTurnsTarget.value ) || 0
        var distance = parseFloat(this.distanceTarget.value ) || 0
        return number_of_turns * distance
    }

    summaryTotalDistancePerMinute() {
        var total = 0

        var speed = parseFloat(this.speedTarget.value ) || 0
        
        if( speed != 0 ) {
            total = ( this.summaryTotalDistance() / speed ) * 60
        }   
        return total
    }
    
    summaryLoadingUnloadingTime(){
        var number_of_turns = parseFloat(this.numberOfTurnsTarget.value ) || 0
        var fueling_time_in_minutes = parseFloat(this.fuelingTimeInMinutesTarget.value ) || 0
        var loading_time_in_minutes = parseFloat(this.loadingTimeInMinutesTarget.value ) || 0
        var unloading_time_in_minutes = parseFloat(this.unloadingTimeInMinutesTarget.value ) || 0

        var total = fueling_time_in_minutes + ( loading_time_in_minutes + unloading_time_in_minutes ) * number_of_turns

        return total
    }

    summaryAdjustedTime(){
        var total  = 0

        var working_time_in_minutes = parseFloat(this.workingTimeInMinutesTarget.value ) || 0
        total = working_time_in_minutes - this.summaryTotalDistancePerMinute() - this.summaryLoadingUnloadingTime()

        return total 
    }

    summaryTotalCost(){
        var total = 0
        var vehicle_fixed_cost_per_day = parseFloat(this.summaryVehicleFixedCostPerDayTarget.innerHTML ) || 0
        var vehicle_variable_cost = parseFloat(this.summaryVehicleVariableCostTarget.innerHTML ) || 0

        total = vehicle_fixed_cost_per_day + ( this.summaryTotalDistance() * vehicle_variable_cost )
        
        return total 
    }

    summaryTotalWeight(){
        var total = 0
        var load_weight = parseFloat(this.loadWeightTarget.value ) || 0
        var plus_weight = parseFloat(this.plusWeightTarget.value ) || 0
        var number_of_turns = parseFloat(this.numberOfTurnsTarget.value ) || 0
        var time_on_road_percentage = parseFloat(this.timeOnRoadPercentageTarget.value ) || 0

        total = ( load_weight + ( ( time_on_road_percentage / 100 ) * plus_weight ) ) * number_of_turns

        return total
    }
 
    summaryTotalCostPerUnit(){
        var total = 0
        if( this.summaryTotalWeight() != 0 ) {
            total = this.summaryTotalCost() / this.summaryTotalWeight()
        }
        
        return total
    }
    
    

}
