import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets  = [ "toggleable", "alertReveneueValue" ]

  connect() {    
    if(parseInt(this.alertReveneueValueTarget.dataset.value) > 0) {
      this.toggleableTarget.classList.remove("d-none");
    } 
  }
}