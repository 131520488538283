// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static get targets() {
    return ['title', 'income_cost', 'working_hours_cost_1', 'working_hours_cost_2', 'ob1_cost_1', 'ob1_cost_2', 'ob2_cost_1', 'ob2_cost_2', 'overtime_cost_1', 'overtime_cost_2', 'truck_variable_mileage_1', 'truck_variable_mileage_2', 'user_input_1_value_1', 'user_input_1_value_2', 'truck_fixed_cost_1', 'truck_fixed_cost_2', 'user_input_2_value_1', 'user_input_2_value_2', 'tb_per_hour', 'desired_tb_per_hour', 'coverage_contribution_per_hour', 'recover_comany_cost', 'working_hours_cost_summary', 'ob1_cost_summary', 'ob2_cost_summary', 'overtime_cost_summary', 'truck_variable_mileage_summary', 'user_input_1_summary', 'truck_fixed_cost_summary', 'user_input_2_summary' ];
  }

  initialize() {
    console.log('stimulus init!');
  }

  connect() {
    //alert("What up");
    console.log('stimulus connect!');
    //this.update();
    this.titleTarget.innerText = ".";
  }

  disconnect() {
    console.log('stimulus disconnect!');
  }


  update(){
    
    const income_cost = parseFloat(this.income_costTarget.value) || 0;
    const tb_per_hour = parseFloat(this.tb_per_hourTarget.value) || 0;

    const working_hours_cost_1 = parseFloat(this.working_hours_cost_1Target.value) || 0;
    const working_hours_cost_2 = parseFloat(this.working_hours_cost_2Target.value) || 0;
    
    const ob1_cost_1 = parseFloat(this.ob1_cost_1Target.value) || 0;
    const ob1_cost_2 = parseFloat(this.ob1_cost_2Target.value) || 0;

    const ob2_cost_1 = parseFloat(this.ob2_cost_1Target.value) || 0;
    const ob2_cost_2 = parseFloat(this.ob2_cost_2Target.value) || 0;

    const overtime_cost_1 = parseFloat(this.overtime_cost_1Target.value) || 0;
    const overtime_cost_2 = parseFloat(this.overtime_cost_2Target.value) || 0;

    const truck_variable_mileage_1 = parseFloat(this.truck_variable_mileage_1Target.value) || 0;
    const truck_variable_mileage_2 = parseFloat(this.truck_variable_mileage_2Target.value) || 0;

    const user_input_1_value_1 = parseFloat(this.user_input_1_value_1Target.value) || 0;
    const user_input_1_value_2 = parseFloat(this.user_input_1_value_2Target.value) || 0;

    const truck_fixed_cost_1 = parseFloat(this.truck_fixed_cost_1Target.value) || 0;
    const truck_fixed_cost_2 = parseFloat(this.truck_fixed_cost_2Target.value) || 0;

    const user_input_2_value_1 = parseFloat(this.user_input_2_value_1Target.value) || 0;
    const user_input_2_value_2 = parseFloat(this.user_input_2_value_2Target.value) || 0;


    const working_hours_cost_summary = working_hours_cost_1 * working_hours_cost_2;
    const ob1_cost_summary = ob1_cost_1 * ob1_cost_2;
    const ob2_cost_summary = ob2_cost_1 * ob2_cost_2;
    const overtime_cost_summary = overtime_cost_1 * overtime_cost_2;

    const truck_variable_mileage_summary = truck_variable_mileage_1 * truck_variable_mileage_2;
    const user_input_1_summary = user_input_1_value_1 * user_input_1_value_2;
    const user_input_2_summary = user_input_2_value_1 * user_input_2_value_2;
    const truck_fixed_cost_summary = truck_fixed_cost_1 * truck_fixed_cost_2;

    const recover_comany_cost =  income_cost - (working_hours_cost_summary + ob1_cost_summary + ob2_cost_summary + overtime_cost_summary + truck_variable_mileage_summary + truck_fixed_cost_summary + user_input_1_summary + user_input_2_summary)

    var coverage_contribution_per_hour = 0
		
		if ( working_hours_cost_1 != 0 ) {
			coverage_contribution_per_hour = recover_comany_cost / working_hours_cost_1
		}    
    
    const desired_tb_per_hour = coverage_contribution_per_hour - tb_per_hour;
    
    this.working_hours_cost_summaryTarget.innerText = working_hours_cost_summary.swedish_format();
    this.ob1_cost_summaryTarget.innerText = ob1_cost_summary.swedish_format();
    this.ob2_cost_summaryTarget.innerText = ob2_cost_summary.swedish_format();
    this.overtime_cost_summaryTarget.innerText = overtime_cost_summary.swedish_format();

    this.truck_variable_mileage_summaryTarget.innerText = truck_variable_mileage_summary.swedish_format();
    this.user_input_1_summaryTarget.innerText = user_input_1_summary.swedish_format();
    this.user_input_2_summaryTarget.innerText = user_input_2_summary.swedish_format();
    this.truck_fixed_cost_summaryTarget.innerText = truck_fixed_cost_summary.swedish_format();

    this.recover_comany_costTarget.innerText = recover_comany_cost.swedish_format();
    this.coverage_contribution_per_hourTarget.innerText = coverage_contribution_per_hour.swedish_format();
    this.desired_tb_per_hourTarget.innerText = desired_tb_per_hour.swedish_format();
        
  }
}
