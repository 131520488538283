import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets  = [ "name", "periodTypeOne", "periodTypeThree", "periodTypesix", "showNames", "generatePeriodStart", "generatePeriodEnd"]
  static values = { url: String }

  connect() {
    console.log("basic cost settings")
  }

  updated(){
    console.log("updated name: " + this.showNamesTarget)

    let url = new URL(this.urlValue);
    url.searchParams.append('name', this.nameTarget.value);    
    url.searchParams.append('period_type', this.getPeriodType());    
    url.searchParams.append('generate_when_type', this.getGenerateWhenType());

    this.showNamesTarget.src = url.toString();
  }

  getPeriodType(){
    if($("#basic_cost_setting_period_type_period_type_one").is(":checked")){
      return "period_type_one"
    } else if($("#basic_cost_setting_period_type_period_type_three").is(":checked")){
      return "period_type_three"
    } else if($("#basic_cost_setting_period_type_period_type_six").is(":checked")){
      return "period_type_six"
    }else{
      return ""
    }
  }

  getGenerateWhenType(){
    if($("#basic_cost_setting_generate_when_type_generate_when_type_period_start").is(":checked")){
      return "generate_when_type_period_start"
    } else if($("#basic_cost_setting_generate_when_type_generate_when_type_period_end").is(":checked")){
      return "generate_when_type_period_end"
    }else{
      return ""
    }
  }

}