// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "email", "paper_invoice", "email_invoice", "ehf_invoice", "plan", "same_address", "co_address", "address", "post_number", "city", "invoice_co_address", "invoice_address", "invoice_post_number", "invoice_city"]

  initialize() {
    console.log('stimulus registration!');
  }

  connect() {

    //this.invoiceDefaultView();
    
    this.planChanged(); 
  }

  disconnet() {
    console.log('stimulus disconnect! registration');
  }

  invoiceDefaultView(){
    if($("#email-invoice").is(":checked")){
      this.updateEmailInvoiceSection(true) ;
      this.updateEhfInvoiceSection(false);
    } else{
      this.updateEmailInvoiceSection(true) ;
      this.updateEhfInvoiceSection(false);
    }


  }

  emailChanged() {
    $.post( "/users/email_company_status", { user: { email: this.emailTarget.value} } );
  }

  emailInvoiceChanged(){
    this.updateEmailInvoiceSection(true) ;
    this.updateEhfInvoiceSection(false) ;
    this.updateInvoiceAddressMonkey();
  }

  ehfInvoiceChanged(){
    this.updateEmailInvoiceSection(false) ;
    this.updateEhfInvoiceSection(true) ;
    this.updateInvoiceAddressMonkey();
  }

  updateEmailInvoiceSection(show = false){
    if(show) {
      $("#invoice-delivery-email-section").slideDown("slow");
    }else{
      $("#invoice-delivery-email-section").slideUp("slow");
    }
  }  

  updateEhfInvoiceSection(show = false){
    if(show) {
      $("#invoice-delivery-ehf-section").slideDown("slow");
    }else{
      $("#invoice-delivery-ehf-section").slideUp("slow");
    }
  }  

  planChanged() {    
    $.get( "/home/plan_info", { plan_id: this.planTarget.value } );
  }

  sameAddressChanged(){
    var status = $('#same-address').is(':checked');    

    if(status) {
      //$("#user_invoice_co_address").prop('readonly', true);
      $("#user_invoice_address").prop('readonly', true);
      $("#user_invoice_post_number").prop('readonly', true);
      $("#user_invoice_city").prop('readonly', true);
      
      this.addressChanged()
    }else{
      //$("#user_invoice_co_address").prop('readonly', false);
      $("#user_invoice_address").prop('readonly', false);
      $("#user_invoice_post_number").prop('readonly', false);
      $("#user_invoice_city").prop('readonly', false);
    }
  }

  addressChanged() {
    var status = $('#same-address').is(':checked'); 
    if (status) {
      //this.invoice_co_addressTarget.value = this.co_addressTarget.value;
      this.invoice_addressTarget.value = this.addressTarget.value;
      this.invoice_post_numberTarget.value = this.post_numberTarget.value;
      this.invoice_cityTarget.value = this.cityTarget.value;    

      $('#user_invoice_address').change();
      $('#user_invoice_post_number').change();
      $('#user_invoice_city').change();
    }
    
  }

  /*registeration page doesn't get active if input with error is removed. we have to trigger change on any field*/
  updateInvoiceAddressMonkey(){
    setTimeout(function() { $('#user_first_last_name').change(); }, 1000);
    console.log("updating");
  }
}
