import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets  = [ "annual_salary", "summary_annual_salary", 
                      "holiday_pay_variation_type", "summary_holiday_pay", 
                      "holiday_pay_variation_21_days", "holiday_pay_variation_25_days",
                      "holiday_pay_variation_21_days_age_over_60", "holiday_pay_variation_25_days_age_over_60",
                      "summary_holiday_pay_next_year",
                      "summary_basis_for_pension_constribution",
                      "pension", "summary_pension",
                      "national_insurance",
                      "injury_insurance", "summary_injury_insurance",
                      "employers_tax", "summary_employers_tax", "employers_contribution_list",
                      "summary", "summary_factor",
                      "year"
                    ]

  connect() {
    this.update()
  }

  update(){
    var annual_salary = parseFloat(this.annual_salaryTarget.value) || 0
    
    var summary_holiday_pay = 0
    var holiday_pay_variation_value = 0
    
    switch ( this.holiday_pay_variation_typeTarget.value ) {
      case "holiday_pay_variation_type_21_days":
        holiday_pay_variation_value = parseFloat(this.holiday_pay_variation_21_daysTarget.value) || 0
        summary_holiday_pay = ( annual_salary / 12 ) * ( 25 / 26 )
        break

      case "holiday_pay_variation_type_25_days":
        holiday_pay_variation_value = parseFloat(this.holiday_pay_variation_25_daysTarget.value) || 0
        summary_holiday_pay = ( annual_salary / 12 ) * ( 30 / 26 )
        break
            
      case "holiday_pay_variation_type_21_days_age_over_60":
        holiday_pay_variation_value = parseFloat(this.holiday_pay_variation_21_days_age_over_60Target.value) || 0
        summary_holiday_pay = ( annual_salary / 12 ) * ( 25 / 26 )
        break

      case "holiday_pay_variation_type_25_days_age_over_60":
        holiday_pay_variation_value = parseFloat(this.holiday_pay_variation_25_days_age_over_60Target.value) || 0
        summary_holiday_pay = ( annual_salary / 12 ) * ( 30 / 26 )
        break

    }         

    var employers_tax = parseFloat(this.employers_taxTarget.value) || 0
    var pension = parseFloat(this.pensionTarget.value) || 0

    var national_insurance = 0
    if (this.hasNational_insuranceTarget) {
      national_insurance = parseFloat(this.national_insuranceTarget.value) || 0
    }
    
    var injury_insurance = parseFloat(this.injury_insuranceTarget.value) || 0

    var summary_holiday_pay_next_year =  ( annual_salary - summary_holiday_pay ) * (holiday_pay_variation_value / 100)

    var summary_basis_for_pension_constribution = annual_salary - summary_holiday_pay + summary_holiday_pay_next_year

    var summary_pension = 0 
    
    if(this.yearTarget.value >= 2022) {
      summary_pension = annual_salary * pension / 100
    } else {
      summary_pension = ( summary_basis_for_pension_constribution - national_insurance ) * pension / 100
    }
    
    var summary_employers_tax = ( summary_basis_for_pension_constribution + summary_pension ) * employers_tax / 100.0
    
    var summary = summary_basis_for_pension_constribution + summary_pension + summary_employers_tax + injury_insurance

    var summary_factor = 0
    if ( annual_salary != 0 ) {
      summary_factor = summary / annual_salary
    }

    this.summary_annual_salaryTarget.innerHTML = annual_salary.swedish_round()
    this.summary_holiday_payTarget.innerHTML = summary_holiday_pay.swedish_round()
    this.summary_holiday_pay_next_yearTarget.innerHTML = summary_holiday_pay_next_year.swedish_round()
    this.summary_basis_for_pension_constributionTarget.innerHTML = summary_basis_for_pension_constribution.swedish_round()
    this.summary_pensionTarget.innerHTML = summary_pension.swedish_round()
    this.summary_employers_taxTarget.innerHTML = summary_employers_tax.swedish_round()
    this.summary_injury_insuranceTarget.innerHTML = injury_insurance.swedish_round()
    this.summaryTarget.innerHTML = summary.swedish_round()
    this.summary_factorTarget.innerHTML = summary_factor.swedish_format()
  }

  employers_contribution_updated(){
    this.employers_taxTarget.value = this.employers_contribution_listTarget.value
    this.update()
  }
}